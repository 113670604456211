var Block = {
	top: 0,
	show: 0,
	id: ""
}
var blocks = [];
var Animate = {
	top: 0,
	show: 0,
	id: 0
}
var animations = [];
var isPage = "";
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");

		common.resize();
		common.nav();
		common.scrl();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		if(isPage === "contact"){
			contact.init();
		}else if(isPage === "index"){
			keyvisual.init();
			setTimeout(function(){
				common.checkPos();
			}, 600);
		}else if(isPage === "download"){
			download.init();
		}
		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		$(document).on("scroll", function(){
			common.scrl();
		});
	},
	nav: ()=>{
		var $body = $("body");
		$("a#btn_menu").off().on("click", function(){
			if(!$body.hasClass("show_nav")){
				$body.addClass("nav");
				setTimeout(function(){
					$body.addClass("show_nav");
				}, 100);
			}else{
				$body.removeClass("show_nav");
				setTimeout(function(){
					$body.removeClass("nav");
				}, 400);
			}
		});
		if(isPage === "index"){
			$(".glbnav li a").off().on("click", function(){
				var idx = $(".glbnav li a").index(this) + 1;
				var posY = blocks[idx].top;
				common.scrollToPosition(posY);
				$body.removeClass("show_nav");
				setTimeout(function(){
					$body.removeClass("nav");
				}, 400);
			});
		}
		
		$("a.btn_pagetop").off().on("click", function(){
			common.scrollToPosition(0);
		});
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		common.checkHeight();
		common.scrl();
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	checkHeight: function(){
		blocks 		= [];
		animations 	= [];
		$(".block").each(function(){
			//console.log($(this))
			var _b 	= object(Block);
			_b.top 	= Math.floor($(this).offset().top - $("header").height());
			_b.show = Math.floor(_b.top + $(window).height()/3);
			_b.id 	= $(this).attr("id");
			//console.log(_b.top)
			blocks.push(_b);
		});
		$(".a").each(function(){
			var _a 	= object(Block);
			_a.top 	= Math.floor($(this).offset().top);
			_a.show = Math.floor(_a.top + $(window).height()/3);
			animations.push(_a);
		});
		console.log(blocks,animations)
	},
	checkPos: function(){
		let param 	= location.search.substring(1),
			pair 	= param.split("&");
		let id = "";
		let hasParam = false;
		for (var i = 0; pair[i]; i++) {
			var kv = pair[i].split("=");
			if (kv[0] === "block") {
				id = kv[1];
				hasParam = true;
			}
		}
		if(hasParam){
			let posY = $(`#${id}`).offset().top;
			common.scrollToPosition(posY);
			window.history.pushState(null, null, "/");
		}
	},
	scrollToPosition: (posY) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height(),
			len  = blocks.length,
			$body = $("body");
		if(isPage === "index"){
			var scrolledPos = $("#blog_content").offset().top;
		}else{
			var scrolledPos = 0;
		}
		if(topD >= scrolledPos){
			if(!$body.hasClass("scrolled")){
				$body.addClass("scrolled");
			}
		}else{
			if($body.hasClass("scrolled")){
				$body.removeClass("scrolled");
			}
		}
		for(var i = 0; i < len; i++){
			if(i === len - 1){
				if(topD >= blocks[i].top){
					activeBlock(i);
				}
			}else{
				if(topD >= blocks[i].top && topD < blocks[i+1].top){
					activeBlock(i);
				}
			}
		}
		function activeBlock(i){
			//1回目hashが変更出来るようになったらスタート
			var $blk = $(".block").eq(i);
			if(!$blk.hasClass("active")){
				$(".block").removeClass("active");
				$("body").removeClass(function(index, className) {
					return (className.match(/\bactive\S+/g) || []).join(' ');
				});
				$("body").addClass("active" + i);
				$blk.addClass("active");
			}
		}
		if(topD < 10){
			$(".a").removeClass("animate animated");
		}
		var alen = animations.length;
		for(var j = 0; j < alen; j++){
			if(j === alen-1){
				if(btmD >= animations[j].show){
					ActiveAnimate(j);
				}
			}else{
				if(btmD >= animations[j].show){
					ActiveAnimate(j);
				}
			}
		}
		function ActiveAnimate(j){
			//1回目hashが変更出来るようになったらスタート
			var $blk = $(".a").eq(j);
			if(!$blk.hasClass("animate")){
				$blk.addClass("animate");
				setTimeout(function(){
					$blk.addClass("animated");
				},1500);
			}
		}

	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});
var container;
var camera, scene, renderer, material, logoobj, light1, controls, clock, sizeby, diviceRate;
var mouseX = 0, mouseY = 0;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

var keyvisual = {
	sizeby: 1,
	count: 90,
	init: function(){
		container = document.getElementById( 'cvs' );
		camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 2000 );
		if(isSP){
			camera.position.z = 300;
		}else{
			camera.position.z = 400;
		}
		var retinaSwitch = window.devicePixelRatio;
		if(retinaSwitch == 1) {
			keyvisual.sizeby = 1;
		} else if(retinaSwitch >= 2) {
			keyvisual.sizeby = 2;
		}
		clock = new THREE.Clock();
		// scene
		scene = new THREE.Scene();
		var ambient = new THREE.AmbientLight( 0x666666);
		scene.add( ambient );

		const c1 	= 0xffffff;
		const intensity 	= .6;
		const distance 		= 600;
		const decay 		= .7;

		var sphere = new THREE.SphereGeometry( 0.25, 16, 8 );
		
		light1 = new THREE.PointLight( c1, intensity, distance, decay );
		light1.add( new THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: c1 } ) ) );
		
		light1.shadow.mapSize.width 	= 2048*sizeby;
		light1.shadow.mapSize.height 	= 2048*sizeby;

		scene.add( light1 );
		var directionalLight = new THREE.DirectionalLight( 0x333333 ,0.22);
		directionalLight.shadow.mapSize.width 	= 2048*sizeby;
		directionalLight.shadow.mapSize.height 	= 2048*sizeby;
		directionalLight.position.set( 0, 1, -0.5 ).normalize();
		directionalLight.castShadow = true;
		//directionalLight.shadowCameraVisible = true; // 確認用四角錐
		scene.add( directionalLight );
		
		// texture
		var manager = new THREE.LoadingManager();
		manager.onProgress = function ( item, loaded, total ) {
			//console.log( item, loaded, total );
		};
		
		var onProgress = function ( xhr ) {
			if ( xhr.lengthComputable ) {
				var percentComplete = xhr.loaded / xhr.total * 100;
				//console.log( Math.round(percentComplete, 2) + '% downloaded' );
			}
		};

		var onError = function ( xhr ) {
		};
		material = new THREE.MeshLambertMaterial({
			color: 0x999999,
			side: THREE.DoubleSide
		});
		// model
		var loader = new THREE.OBJLoader(manager);
		loader.load( '/logo.obj', function ( object ) {
			object.traverse( function ( child ) {
				if ( child instanceof THREE.Mesh ) {
					child.material = material;
				}
			});
			object.material = material;
			object.position.y = -60;
			logoobj = object;
			logoobj.castShadow = true;
			logoobj.rotation.z = .1;
			logoobj.rotation.x = Math.PI*-.3;
			console.log(logoobj)
			scene.add( logoobj );
			//
			var dlight = new THREE.DirectionalLight( 0xffffff, 0.25);
			dlight.shadow.mapSize.width 	= 1024*sizeby;
			dlight.shadow.mapSize.height 	= 1024*sizeby;
			dlight.position.set( 0.5, -1, 0 ).normalize();
			
			scene.add( dlight );
			scene.fog = new THREE.Fog(0x000000, 100, 500);
			
			//scene.fog = new THREE.FogExp2(0xffffff, 0.00135);
			renderer = new THREE.WebGLRenderer({ alpha: true });
			renderer.shadowMap.enabled = true;
			renderer.setClearColor( 0x000000, 0 );
			renderer.setPixelRatio( window.devicePixelRatio );
			renderer.setSize( window.innerWidth, window.innerHeight );
			container.appendChild( renderer.domElement );

			document.addEventListener( 'mousemove', onDocumentMouseMove, false );
			window.addEventListener( 'resize', keyvisual.resize, false );
			keyvisual.animate();
			$("body").removeClass("disable");
			function onDocumentMouseMove( event ) {
				mouseX = ( event.clientX - windowHalfX ) / 4;
				mouseY = ( event.clientY - windowHalfY ) / 4;
			}

		}, onProgress, onError );
		
	},
	resize: function(){
		windowHalfX = window.innerWidth / 2;
		windowHalfY = window.innerHeight / 2;

		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();

		renderer.setSize( window.innerWidth, window.innerHeight );
		if(window.innerWidth < 864){
			diviceRate = .5;
		}else{
			diviceRate = 1;
		}
	},
	animate: function(){
		requestAnimationFrame( keyvisual.animate );
		update();
		
		function update(){
			var time = Date.now() * 0.00025;
			const z = 20, d = 1000;
			light1.position.x = Math.sin( time * 0.7 ) * d;
			light1.position.y = Math.sin( time * 0.3 ) * d;
			light1.position.z = Math.cos( time * 0.3 ) * d;
			//controls.update( clock.getDelta() );
			logoobj.rotation.x += Math.PI*Math.sin(time*.0004)/400;
			//logoobj.rotation.y += Math.PI*Math.tan(time*.0004)/400;
			logoobj.rotation.z += Math.PI*Math.cos(time*.0004)/800;
			var s = 5;
			logoobj.scale.set(s,s,s);
			keyvisual.count += .0008;
			render();
		}
		function render(){
			var time = Date.now() * 0.00025;
			//camera.rotation.z += Math.PI*Math.sin(time*.0001)/200;
			//camera.lookAt( 0 );
			camera.lookAt( scene.position );
			renderer.render( scene, camera );
		}
	}
}
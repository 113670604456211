var userAgent 	= window.navigator.userAgent.toLowerCase();
var appVersion 	= window.navigator.appVersion.toLowerCase();
var isLegacy 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1;
var isIE10 		= appVersion.indexOf("msie 10.0") > -1;
var isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1;
var isIE8over 	= appVersion.indexOf("msie 8.0") > -1 || appVersion.indexOf("msie 9.0") > -1 || appVersion.indexOf("msie 10.0") > -1 ||  appVersion.indexOf("msie 11.0") > -1;
var isIE 		= userAgent.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/);
var isAndroid 	= navigator.userAgent.match(/Android/i);
var isIPhone 	= navigator.userAgent.match(/iPhone/i);
var isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i);
var isIPad 		= navigator.userAgent.match(/iPad/i);
var isFF 		= userAgent.indexOf("firefox")!== -1;
var isEdge 		= userAgent.indexOf("applewebkit") > -1 && userAgent.indexOf("edge") > -1;
var isMobile	= true;
var isSmallScreen = false;
var isPC = true;
if(isAndroid || isIPhone || isIPad){
	isPC = false;
}else{
	isPC = true;
}
var $html = document.documentElement;
//win or mac
if(navigator.userAgent.indexOf("Win") !== -1 ){
	if(isFF){
		$html.classList.add("os_windows", "firefox");
	}else{
		if(isIE){
			if(isIE10){
				$html.classList.add("os_windows", "legacy");
			}else if(isIE11){
				$html.classList.add("os_windows", "ie11");
			}
		}else if(isEdge){
			$html.classList.add("os_windows", "edge");
		}else{
			$html.classList.add("os_windows");
		}
	}
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	var cls = "";
	if(isFF){
		$html.classList.add("os_mac", "firefox");
	}else{
		if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1){
			$html.classList.add("os_mac", "safari");
		}else{
			$html.classList.add("os_mac");
		}
	}
}
var load = {
	getJson: function (url, params) {
		var defer = $.Deferred();
		$.ajax({
			type: "GET",
			dataType: "json",
			url: url,
			data: params,
			success: defer.resolve,
			error: function(xhr, status, err){
				console.log(xhr);
				console.log(status);
				console.log(err);
				defer.reject(xhr,status,err);
			}
		});
		return defer.promise();
	}
}
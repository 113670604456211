let download = {
	init: function(){
		let url = "/download.json";
		load.getJson(url, {}).done(function(json){
			console.log(json);
			$("a.btn_download_all").attr("href", json.allFiles);
			$("#block_movie a").attr("href", json.adlSchool);
			download.makeHTML(json);
		});
	},
	makeHTML: function(json){
		console.log(json);
		let data = json.section;
		let HTML = "";
		for(var i = 0; i < data.length; i++){
			let section = data[i];
			HTML += `<div class="dl">
						<div class="ttl_dl">
							<h4>
								<span>${section.title}</span>
							</h4>
						</div>
						<ul class="list_dl">`;
			for(var c = 0; c < section.chapters.length; c++){
				let chapter = section.chapters[c];
				HTML += `<li><a href="${chapter.file}" download=""><div class="inr"><span class="ttl_chapter">${chapter.chapter}</span><br><span>${chapter.name}</span><i><svg x="0px" y="0px" viewBox="0 0 14 17" style="enable-background:new 0 0 14 17;" xml:space="preserve"><path class="st0" d="M14,6h-4V0H4v6H0l7,7L14,6z M0,15v2h14v-2H0z"></path></svg></i></div></a></li>`
			}
			console.log(section)
			HTML += `</ul></div>`;
		}
		$("#block_download .inner").html(HTML);
		download.nav();
	},
	nav: function(){
		$("a.toggle_sample").off().on("click", function(){
			$("#block_download").animate({
				height: "toggle", opacity: "toggle"
			}, 600);
			if( !$("#block_sample").hasClass("active") ){
				$("#block_sample").addClass("active");
			}else{
				$("#block_sample").removeClass("active");
			}
		});
	}
}
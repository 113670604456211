var contact = {
	init: function(){
		$(".wpcf7-list-item").each(function(i){
			//console.log(i);
			$(this).find("label").prepend('<span class="radio-icon"></span>');
		});
		$(".select_classify li label").off().on("click", function(){
			if(!$(this).hasClass("active")){
				$(".select_classify li label").removeClass("active");
				$(this).addClass("active");
			}
		});
		document.addEventListener( 'wpcf7mailsent', function( event ) {
			$("#block_contact").addClass("thanks");
		}, false );
	}
}